import Model from './Model'
import Field from '../types/Field'

export default class EventType extends Model {
    endpoint = 'event-types';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      /* new Field('extra_public_price', 'Precio público extra', 'number').applyMask(value => `$${helpers.numberFormat(value)}`),
        new Field('extra_net_price', 'Precio neto extra', 'number').applyMask(value => `$${helpers.numberFormat(value)}`),
      */
    ];

    clone = () => EventType;
}
